import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	Label,
	CartesianGrid,
	Tooltip,
} from "recharts";
import {
	GraphBundle,
	LineGraphOptions,
	Series,
	graphOptionsBase,
} from "../../types/types";

function CustomizedYAxisTick(props: any) {
	const { x, y, stroke, payload } = props;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy={0}
				fill="white"
				transform="rotate(-0)"
				textAnchor="end"
				style={{ fontSize: "0.7rem" }}
			>
				{"$" + Number(payload.value).toLocaleString()}
			</text>
		</g>
	);
}

function convertToISO(dateString: string): string {
	const months: { [key: string]: string } = {
		January: "01",
		February: "02",
		March: "03",
		April: "04",
		May: "05",
		June: "06",
		July: "07",
		August: "08",
		September: "09",
		October: "10",
		November: "11",
		December: "12",
	};

	if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
		return dateString;
	}

	let [monthName, day] = dateString.split("-");

	const month = months[monthName as keyof typeof months]; // Type assertion here

	if (!month) {
		throw new Error(`Invalid month name: ${monthName}`);
	}

	const year = new Date().getFullYear();
	day = day.padStart(2, "0");

	return `${year}-${month}-${day}`;
}

const monthNames = ["January"];

// function CustomizedXAxisTick(props: any) {
// 	const { x, y, stroke, payload } = props;
// 	const date = new Date(payload.value);
// 	return (
// 		<g transform={`translate(${x},${y})`}>
// 			<text
// 				x={0}
// 				y={0}
// 				dy={0}
// 				fill="white"
// 				transform="rotate(-0)"
// 				style={{ fontSize: "0.875rem" }}
// 			>
// 				{`${date.toLocaleString("default", {
// 					month: "long",
// 				})}-${date.getDate()}`}
// 			</text>
// 		</g>
// 	);
// }

const CustomTooltip = (props: any) => {
	const { active, payload, label } = props;
	if (active && payload && payload.length) {
		const date = payload[0].payload.t;
		return (
			<div className="p-3 text-dark bg-light">
				<p>{`${date}`}</p>
				<p>{`${"$" + Number(payload[0].payload.y).toFixed(2)}`}</p>
			</div>
		);
	}

	return null;
};

export default function LineGraph({
	data,
	graphOptions,
}: {
	data: Series[] | undefined;
	graphOptions: LineGraphOptions;
}) {
	if (graphOptions.timeSeries === "true" && data) {
		data = data.map((entry) => {
			const date = new Date(convertToISO(String(entry["t"])));
			const year = date.getFullYear();
			const month = date.getMonth();
			const day = date.getDate();
			const months = [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December",
			];
			entry["t"] = `${months[month]}-${String(day).padStart(2, "0")}`;
			return entry;
		});
	}
	return (
		data && (
			<ResponsiveContainer height="100%" width="100%">
				<LineChart
					data={data}
					{...{
						overflow: "visible",
					}}
				>
					<XAxis
						dataKey="t"
						// scale="utc"
						// type="number"
						style={{
							fontSize: "0.7rem",
							fill: "white",
						}}
						label={{
							value: graphOptions.independent,
							position: "insideBottomRight",
							offset: -5,
							fontSize: "1rem",
						}}
					/>
					<YAxis
						type="number"
						height={100}
						width={30}
						tick={CustomizedYAxisTick}
						label={{
							value: graphOptions.dependent,
							position: "insideTopLeft",
							fontSize: "1rem",
							offset: -25,
						}}
					/>
					<Line
						dataKey="y"
						fill="#8884d8"
						isAnimationActive={true}
						style={{ stroke: "#77D4E1" }}
						dot={data.length < 15}
					/>
					{/* <Tooltip /> */}
					<Tooltip content={<CustomTooltip />} />
				</LineChart>
			</ResponsiveContainer>
		)
	);
}
